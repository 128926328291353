.alert-container {
  min-height: 0;
  margin-bottom: 0;
  margin-top: 1em;
  border-radius: 3px;
  padding: 0;
}

.alert-transparent {
  min-height: 0;
  margin-bottom: 0;
  margin-top: 1em;
  border-radius: 3px;
  padding: 0;
  box-shadow: none;
  background: transparent;
  min-width: 528px;
}

.alert-container > .Toastify__close-button {
  display: none;
}

.ds-toast-container {
  min-height: 0;
  margin-bottom: 0;
  margin-top: 1em;
  border-radius: 8px;
  padding: 8px 4px;
}
.ds-toast-container .Toastify__toast-body > div {
  background: transparent;
  border: none;
  font-family: 'Effra';
  font-weight: normal;
  align-items: center;
}
.ds-toast-container p {
  padding-left: 8px;
}
.ds-toast-container.success {
  background-color: #ddfddd;
  color: #186818;
  border: 1px solid #186818;
}
.ds-toast-container.success .Toastify__toast-body > div {
  color: #186818;
}
.ds-toast-container.error {
  background-color: #fee0dc;
  color: #7f0f01;
  border: 1px solid #7f0f01;
}
.ds-toast-container.error .Toastify__toast-body > div {
  color: #7f0f01;
}
.ds-toast-container > .Toastify__close-button {
  display: none;
}
.ds-toast-container.info {
  background-color: #ededf7;
  color: #292839;
  border: 1px solid #292839;
}
.ds-toast-container.info .Toastify__toast-body > div {
  color: #292839;
}
