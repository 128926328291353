.DatePicker__input {
  min-width: 0;
  border-radius: 2px;
  background-color: #fafbfc;
  border: 1px solid #eeeeee;
  font-size: 0.875rem;
  display: inline-block;
  transition: border-color 0.1s, box-shadow 0.1s;
  height: 35px;
  width: 100%;
  padding: 5px 10px;
  font-size: 12px;
  color: #707f8f;
}
